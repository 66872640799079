.common-page-title {
    font-size: 90px;
    margin-bottom: 70px;
    line-height: 86px;
    font-weight: 500;
    letter-spacing: 0.47px;
    text-align: center;
    white-space: nowrap;
}

.common-main-page-title {
    font-size: calc(72px + 1vw);
    line-height: calc(72px + 1vw);
    font-weight: 500;
    letter-spacing: 0.47px;
    text-align: center;

    font-size: calc(72px + 1vw);
    line-height: calc(72px + 1vw);

    @media (max-width: 1150px) {
        font-size: 42px;
        line-height: 42px;
    }
    @media (max-width: 640px) {
        font-size: 42px;
        line-height: 42px;
    }
}

.common-page-inner-content {
    padding: 135px 140px 160px;
}

.common-page-subtitle {
    font-size: 24px;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: 1.5px;
    margin-bottom: 3px;
}

.common-page-paragraph {
    font-family: 'LinearSans';
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 50px;
    white-space: pre-wrap;
}

.common-page-subsection-title {
    font-family: 'LinearSans';
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-decoration: underline;
}

.common-page__grazer-logo {
    width: 140px;
    height: 18px;
}

.common-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: LinearSans;
    padding: 0;
    font-size: 16px;
    line-height: 27.6px;
    letter-spacing: 1.5px;
    font-weight: 400;
    background: #55a062;
    color: #f9f9f2;
    border-radius: 100px;
    text-decoration: none;
    height: 48px;
    cursor: pointer;
}

/* Mobile Styling */

@media (max-width: 1150px) {
    .common-page-inner-content {
        padding: 68px 32px 330px 32px;
    }

    .common-page-title {
        font-size: 42px;
        margin-bottom: 40px;
        line-height: 26px;
    }

    .common-page-subtitle {
        font-size: 18px;
        line-height: 23px;
    }

    .common-page-paragraph {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 35px;
    }

    .common-page-subsection-title {
        font-size: 16px;
        line-height: 23px;
    }

    .common-button {
        font-size: 16px;
        line-height: 21.6px;
    }
}
