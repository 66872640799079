.page-footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1E1E1E;
    padding: 0 53px 0 55px;
    height: 143px;
    z-index: 2;
}

.page-footer__logo-container {
    display: flex;
    margin-bottom: 16px;
}

.page-footer__grazer-logo {
    height: 50px;
    width: auto;
}

.page-footer__grazer-slogan {
    display: flex;
    margin-left: 28px;
    color: #F8F8F1;
    font-size: 20px;
}

.page-footer__links-container {
    display: flex;
    align-items: center;
}

.page-footer__links-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 590px;
}

.page-footer__link {
    font-family: 'LinearSans';
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 21.6px;
    width: 139px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.page-footer__link a {
    text-decoration: none;
    color: #F8F8F1;
}

.page-footer__link.pressed a {
    color: #55A062;
}

.page-footer__icons-container {
    display: flex;
}

.page-footer__icons-container img {
    width: 48px;
    height: 40px;
}

.page-footer__icons-container a:not(:first-child) {
    margin-left: 41px;
}


/* Mobile styling */

@media (max-width: 1180px) {
    .page-footer__container {
        flex-direction: column;
        align-items: flex-start;
        padding: 26px 0 33px 22px;
        height: unset;
    }

    .page-footer__grazer-slogan-mobile {
        display: flex;
        margin-top: 9px;
        margin-bottom: 44px;
    }

    .page-footer__logo-container {
        flex-direction: column;
    }

    .page-footer__links-container {
        flex-direction: column;
        align-items: flex-end;
        width: calc(100% - 22px);
    }

    .page-footer__links-list {
        max-width: unset;
        width: 100%;
    }

    .page-footer__link {
        font-size: 15px;
        line-height: 18px;
        width: auto;
        margin-bottom: 6px;
        margin-top: 6px;
        padding-right: 48px;
    }

    .page-footer__icons-container {
        margin-top: 24px;
        padding-right: 11px;
    }

    .page-footer__icons-container img {
        width: 24px;
        height: 24px;
    }

    .page-footer__icons-container a:not(:first-child) {
        margin-left: 26px;
    }
}
@media (max-width: 400px) {
    .page-footer__link  {
        padding-right: 64px;
    }
}
