@font-face {
  font-family: 'LinearSans';
  src: url('./resources/fonts/LinearSans.otf') format('opentype');
}

@font-face {
  font-family: 'Recoleta';
  src: url('./resources/fonts/Recoleta.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Recoleta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F2;
  color: #1E1E1E;
}

.mobile-only {
  display: none !important;
}

@media (max-width: 576px) {
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: flex !important;
  }
}
